/* components/Homepage.css */
body {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-family: monospace;
        font-size: calc(2vw + 2vh);

        background-size: cover;

        background-image: url("../../public/images/background.png");
        width: 100vw;
        height: 100vh;
        text-align: center;
  }
  
  .logo{
    width: calc(5vw + 20vh);
    border-radius: 50%;
  }

  a{
    color: red;
  }

  h6{
    cursor: pointer;
  }
  
  h6:hover{
    transform: scale(1.10);
  }